<template>
  <v-container
    id="admin-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="light-blue">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Modificar perfil
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Complete su perfil
            </div>
          </template>
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="editedItem.email"
                  :rules="emailRules"
                  label="Correo electrónico"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="info"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>El cambio de correo electrónico requiere un nuevo inicio de sesión</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editedItem.nombre"
                  :rules="[v => !!v || 'El nombre es requerido']"
                  label="Nombre"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editedItem.apellido"
                  :rules="[v => !!v || 'El apellido es requerido']"
                  label="Apellido"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Nueva contraseña"
                  hint="Ingresa al menos 8 caracteres"
                  counter
                  @click:append="show1 = !show1"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.confirmation"
                  :rules="rules"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  label="Confirmar contraseña"
                  hint="Ingresa al menos 8 caracteres"
                  counter
                  @click:append="show2 = !show2"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="primary"
                  class="mr-0"
                  @click="save(editedItem.id)"
                >
                  Modificar perfil
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'Profile',

    data: vm => {
      return {
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        valid: true,
        lazy: false,
        show1: false,
        show2: false,
        allowSpaces: false,
        editedItem: {
          id: '',
          nombre: '',
          apellido: '',
          email: '',
          password: '',
          confirmation: '',
        },
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Debes ingresar un correo electrónico válido',
        ],
      }
    },
    computed: {
      rules () {
        const rules = []

        if (!this.allowSpaces) {
          const rule =
            v => (v || '').indexOf(' ') < 0 ||
              'No se permiten espacios en la contraseña'

          rules.push(rule)
        }

        if (this.editedItem.password) {
          const rule =
            v => (!!v && v) === this.editedItem.password ||
              'Las contraseñas no coinciden'

          rules.push(rule)
        }

        return rules
      },
    },

    created () {
      this.fetchProfile()
    },

    methods: {
      fetchProfile () {
        this.$store.dispatch('usuarios/getUserAuth')
          .then((response) => {
            this.editedItem = Object.assign({}, response.data)
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            Vue.notify({
              group: 'loggedIn',
              text: 'No se pudo eliminar en este momento',
              type: 'red accent-2',
              duration: 5000,
            })
          },
          )
      },
      save (id) {
        if (this.$refs.form.validate()) {
          this.$store.dispatch('usuarios/updateProfile', this.editedItem)
            .then((response) => {
              Vue.notify({
                group: 'loggedIn',
                text: response.data.success,
                type: 'teal accent-3',
                duration: 5000,
              })
              if (response.data.email) {
                setTimeout(() => {
                  this.$store.dispatch('logout')
                }, 3000)
              }
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log(err)
              Vue.notify({
                group: 'loggedIn',
                text: 'No se pudo eliminar en este momento',
                type: 'red accent-2',
                duration: 5000,
              })
            },
            )
        }
      },
    },
  }
</script>
